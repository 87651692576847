amount: Amount
back: Back
back-to-home: Back to Home
button:
  about: About
  back: Back
  go: GO
  home: Home
  toggle_dark: Toggle dark mode
  toggle_langs: Change languages
cancel: Cancel
close: Close
columns-select: 'Show:'
columns-select-placeholder: Show...
created-at: Created
dashboard: Dashboard
email-already-sent: Email already sent, please retry after {retryAfter} seconds.
email-not-valid: Email address not valid
email-sent-desc: >-
  A verification email has been sent. Please check your inbox and click the link
  to activate your account.
emial-sent-title: Email Sent
fee: Fee
intro:
  aka: Also known as
  desc: Opinionated Vite Starter Template
  dynamic-route: Demo of dynamic route
  hi: Hi, {name}!
  whats-your-name: What's your name?
liquidation-status.0: Created
liquidation-status.1: Waiting for Bank liquidation
liquidation-status.2: Waiting for CEX
liquidation-status.3: Completed
liquidations: Liquidations
login: Login
login-with-metamask: Login with MetaMask
logout: Logout
max-refundable-amount: Max refundable amount
merchant-stage.ACCOUNT_FREEZED: Account freezed
merchant-stage.ACCOUNT_INACTIVE: Account inactive
merchant-stage.EMAIL_NOT_VERIFIED: Email not verified
merchant-stage.KYC_PENDING: KYC pending
merchant-stage.KYC_REJECTED: KYC rejected
merchant-stage.KYC_REQUIRED: KYC required
merchant-stage.OK: OK
merchant-stage.TWO_FACTOR_REQUIRED: Two factor required
next: Next
no-actions-avil: No actions available
no-refunds: No refunds
not-found: Not found
open-kyc-form: Open KYC Form
order-id: Order ID
order-status: Status
otp-code-not-valid: OTP code is not valid
otp-verified: OTP verified
otp-verified-desc: You have successfully verified OTP, now you can login with your OTP token
otp-verified-desc2: Please remember that you need to input OTP token on every login
payments: Payments
please-enter-email-address: Please enter email address
please-enter-email-verify-token: Please enter email verify token
please-enter-otp-code: Please enter OTP code
please-enter-otp-to-login: Please enter the OTP code to login.
price: Amount
product-desc: Description
product-name: Name
refund-amount: Amount
refund-requests: Refund History
refund-status.0: Pending
refund-status.1: Success
refund-status.2: Failed
refunded-amount: Refunded
refunds: Refunds
refunds-of: Refunds of
rejected-reason: Reason
settlement-amount: Settlement
start-bind-email: Start bind email
start-setup-otp: Start setup OTP
start-verify-email: Start verify email
submit-email-verify-token: submit
submit-otp-code: Submit
submit-refund: Submit Refund
tx-refund-max-reached: Not refundable
tx-status-expired: This payment has expired
tx-status-failed: This payment has failed
tx-status-partial-refund: '{0} of {1} refunded'
tx-status-pending: This payment is pending
tx-status-pending-refund: '{0} of {1} refunded'
tx-status-refunded: '{0} refunded'
tx-status-success: This payment has succeeded
tx-status.0: Pending
tx-status.1: Success
tx-status.2: Failed
tx-status.3: Refunded
tx-status.4: Expired
tx-status.5: PartialRefund
tx-status.6: PendingRefund
updated-at: Last Update
